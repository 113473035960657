.Header {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

  .coin-selector {
    margin-right: 20px;
    position: relative;
    top: -4px;
  }

  .explorer-selector-block {
    margin-left: 10px;

    .explorer-selector {
      opacity: 0;
      position: absolute;
      width: 20px;
      margin-left: -18px;
      margin-top: 2px;
    }
  }
}