// ref: https://www.w3schools.com/howto/howto_css_switch.asp

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  white-space: nowrap;
  margin: 7px 0 25px 20px;
  cursor: pointer;

  .slider-text {
    position: relative;
    top: 1px;
    margin-left: 40px;
  }

  &.dev {
    margin: 7px 20px 25px 0px;

    .slider-text {
      margin-left: 60px;
    }
  }

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }


  /* Rounded sliders */
  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

input:checked + .slider {
  background-color: #41EAD4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #41EAD4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}