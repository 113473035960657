.container {
  .navbar-brand {
    margin: 0 auto !important;
  }
}

#body {
  background: #FCFDFF;
  color: #121958 !important;

  strong,
  h2,
  h3 {
    color: #121958 !important;
  }

  a,
  a:hover {
    color: #13D0A7;
  }

  .coins-holdings-block {
    h3 {
      font-weight: bold;
      font-size: 18px;
      color: #9a9a9a !important;
      text-align: left;
    }
    .inline-coin-icons {
      margin-top: 10px;
    }
  }
  .notification.is-success {
    * {
      color: #fff !important;
    }
    .qr-gen-modal {
      * {
        color: #121958 !important;
      }
    }
  }
  .notification.is-danger {
    * {
      color: #fff !important;
    }
  }
  .Modal {
    z-index: 110 !important;
  }
}

.Header {
  &.navbar {
    background: #fff;
  }
}

.main {
  margin-left: 90px;

  .vendor-trezor,
  .vendor-ledger {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  }

  &.main-full {
    margin-left: 200px;
  }
}

.bottom-blocks {
  display: flex;
  flex-direction: row;
  margin-top: 35px;

  > div {
    flex-grow: 1;

    > div {
      height: 215px;
    }
  }

  h4 {
    text-align: left;
  }
}

.dashboard {
  .hw-graphic {
    top: 60% !important;
  }

  .container.content.content-init-hw-vendor {
    padding-top: 30px !important;
    text-align: left;
  
    .hw-graphic {
      top: -45px !important;
    }
  }
}

.ActionListModal.Scan-balances-modal .Modal,
.ActionListModal.Receive-modal .Modal,
.ActionListModal.send-coin-modal .Modal {
  text-align: left;

  .panel-heading,
  .panel-tabs,
  .panel-block,
  .panel-block.is-active {
    border-bottom: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }

  .panel .panel-block:first-child {
    border-top: 1px solid #dbdbdb;
  }

  .table {
    margin: 25px 0 35px 0;
  }

  .button {
    margin: 0 auto;
    display: block;
  }
}

.trezor-webusb-container {
  display: none;
}

.hidden {
  display: none !important;
}

.no-hover:hover {
  td {
    background: #fff !important;
    cursor: default;
  }
}

.del-btn {
  i {
    font-size: 13px;
    position: relative;
    top: -1px;
    margin-left: 10px;
  }
}

.text-left {
  text-align: left !important;
}

.modal-action-block {
  text-align: right;
}

.claim-rewards-btn {
  margin-top: 30px !important;
  margin-bottom: 0 !important;

  i {
    color: #fff !important;
    position: relative;
    top: -1px;
    margin-right: 10px;
  }
}

.Modal-coin-settings {
  .button:first-child {
    margin-right: 40px;
  }
}

input[type="text"] {
  padding: 6px 10px;
  min-width: 310px;
  border: thin solid rgb(170, 170, 170);
  border-radius: 2px;
  outline: none;
}

.button.is-light.send-max {
  position: relative;
  top: -1px;
  left: 10px;
  height: 31px;
}

.button.is-primary.btn-back {
  margin-bottom: 30px !important;
}

.left {
  text-align: left;
}

.notification.is-success {
  .copy-btn {
    background: transparent !important;
    border: solid 1px #fff !important;
    float: none;
    display: inline-block !important;
    margin: 0 0 0 15px !important;
    font-size: 0.95em;
    padding: 0 7px;
    height: 28px;
    position: relative;
    top: -2px;

    i {
      font-size: 0.9em;
    }
  }
}

#js-copytextarea {
  opacity: 0;
  height: 10px;
  width: 200px;
}

.copy-btn {
  font-size: 0.8rem;
  padding: 0.25rem 0.4rem;
  margin: 0 0 0 10px !important;
  margin-left: 10px;
  height: 1.4rem;
  text-transform: lowercase;

  .copy-btn-text {
    position: relative;
    top: -1px;
  }
  i {
    margin-right: 5px;
    font-size: 0.75rem;
  }
}

.Modal-about {
  a,
  a:hover {
    text-decoration: underline;
    color: #121958 !important;
  }
}

.login-modal {
  .settings-modal-trigger-block {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 13px;
    cursor: pointer;
    font-size: 1.2em;
  }
}

.accounts-airdop-helper-link {
  margin-top: 20px;
  text-align: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sync-progress-icon {
  animation: rotate 2s linear 0s infinite normal;
  font-size: 0.9em;
  position: absolute;
  left: 200px;
}

.scroll {
  overflow-y: auto !important;
}

.no-scroll {
  overflow-y: hidden !important;
}

.back-to-top-button {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 4px rgb(0 0 0 / 15%);
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
  &.invisible {
    right: -40px;
  }
  i {
    position: relative;
    top: 8px;
    left: 12px;
  }
}

.coin-icons-wrapper-container {
  position: relative;
  width: 35px;
  height: 35px;
  display: inline-block;
}
.coin-icons-wrapper {
  position: absolute;
}