
    .coin-icons {
      
      &.AXO {
        width: 80px;
        height: 80px;
        background-position-x: -1461px;
        background-position-y: -592px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.BCZERO {
        width: 200px;
        height: 200px;
        background-position-x: -1179px;
        background-position-y: -486px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.BET {
        width: 80px;
        height: 80px;
        background-position-x: -1541px;
        background-position-y: -592px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.BOTS {
        width: 80px;
        height: 80px;
        background-position-x: -1259px;
        background-position-y: -686px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.BTCH {
        width: 80px;
        height: 80px;
        background-position-x: -1339px;
        background-position-y: -686px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.CCL {
        width: 80px;
        height: 80px;
        background-position-x: -1419px;
        background-position-y: -686px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.CLC {
        width: 80px;
        height: 80px;
        background-position-x: -1499px;
        background-position-y: -686px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.COQUICASH {
        width: 80px;
        height: 80px;
        background-position-x: -1579px;
        background-position-y: -686px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.CRYPTO {
        width: 80px;
        height: 80px;
        background-position-x: -1179px;
        background-position-y: -788px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.DEX {
        width: 80px;
        height: 80px;
        background-position-x: -1259px;
        background-position-y: -788px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.DOC {
        width: 80px;
        height: 80px;
        background-position-x: -1339px;
        background-position-y: -788px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.DP {
        width: 82px;
        height: 82px;
        background-position-x: -1379px;
        background-position-y: -592px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.GLEEC {
        width: 64px;
        height: 64px;
        background-position-x: -1179px;
        background-position-y: -1108px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.HODL {
        width: 80px;
        height: 80px;
        background-position-x: -1419px;
        background-position-y: -788px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.ILN {
        width: 80px;
        height: 80px;
        background-position-x: -1499px;
        background-position-y: -788px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.JUMBLR {
        width: 80px;
        height: 80px;
        background-position-x: -1579px;
        background-position-y: -788px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.KMD {
        width: 80px;
        height: 80px;
        background-position-x: -1179px;
        background-position-y: -868px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.KOIN {
        width: 80px;
        height: 80px;
        background-position-x: -1259px;
        background-position-y: -868px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.LABS {
        width: 80px;
        height: 80px;
        background-position-x: -1339px;
        background-position-y: -868px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.MARTY {
        width: 80px;
        height: 80px;
        background-position-x: -1419px;
        background-position-y: -868px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.MCL {
        width: 80px;
        height: 80px;
        background-position-x: -1499px;
        background-position-y: -868px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.MDX {
        width: 1179px;
        height: 1179px;
        background-position-x: -0px;
        background-position-y: -0px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.MESH {
        width: 80px;
        height: 80px;
        background-position-x: -1579px;
        background-position-y: -868px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.MGW {
        width: 80px;
        height: 80px;
        background-position-x: -1179px;
        background-position-y: -948px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.MORTY {
        width: 80px;
        height: 80px;
        background-position-x: -1259px;
        background-position-y: -948px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.MSHARK {
        width: 80px;
        height: 80px;
        background-position-x: -1339px;
        background-position-y: -948px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.NINJA {
        width: 80px;
        height: 80px;
        background-position-x: -1419px;
        background-position-y: -948px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.PANGEA {
        width: 80px;
        height: 80px;
        background-position-x: -1499px;
        background-position-y: -948px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.PGT {
        width: 80px;
        height: 80px;
        background-position-x: -1579px;
        background-position-y: -948px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.RAPH {
        width: 486px;
        height: 486px;
        background-position-x: -1179px;
        background-position-y: -0px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.REVS {
        width: 80px;
        height: 80px;
        background-position-x: -1179px;
        background-position-y: -1028px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.RICK {
        width: 80px;
        height: 80px;
        background-position-x: -1259px;
        background-position-y: -1028px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.SOULJA {
        width: 80px;
        height: 102px;
        background-position-x: -1179px;
        background-position-y: -686px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.SPACE {
        width: 80px;
        height: 80px;
        background-position-x: -1339px;
        background-position-y: -1028px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.SUPERNET {
        width: 80px;
        height: 80px;
        background-position-x: -1419px;
        background-position-y: -1028px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.THC {
        width: 80px;
        height: 80px;
        background-position-x: -1499px;
        background-position-y: -1028px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.TKLTEST {
        width: 116px;
        height: 106px;
        background-position-x: -1379px;
        background-position-y: -486px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.TOKEL {
        width: 116px;
        height: 106px;
        background-position-x: -1495px;
        background-position-y: -486px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.VOTE2024 {
        width: 80px;
        height: 80px;
        background-position-x: -1579px;
        background-position-y: -1028px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.VRSC {
        width: 80px;
        height: 80px;
        background-position-x: -0px;
        background-position-y: -1179px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.WLC21 {
        width: 80px;
        height: 80px;
        background-position-x: -80px;
        background-position-y: -1179px;
        background-size: 1665px 1259px;
        position: absolute;
      }
      &.WSB {
        width: 64px;
        height: 64px;
        background-position-x: -1243px;
        background-position-y: -1108px;
        background-size: 1665px 1259px;
        position: absolute;
      }
    }
      .AXO-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .AXO-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .BCZERO-icon-size-md {
        transform: scaleX(17.5%) scaleY(17.5%);
      }
      .BCZERO-icon-size-sm {
        transform: scaleX(15%) scaleY(15%);
      }
    
      .BET-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .BET-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .BOTS-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .BOTS-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .BTCH-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .BTCH-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .CCL-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .CCL-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .CLC-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .CLC-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .COQUICASH-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .COQUICASH-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .CRYPTO-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .CRYPTO-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .DEX-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .DEX-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .DOC-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .DOC-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .DP-icon-size-md {
        transform: scaleX(42.68292682926829%) scaleY(42.68292682926829%);
      }
      .DP-icon-size-sm {
        transform: scaleX(36.58536585365854%) scaleY(36.58536585365854%);
      }
    
      .GLEEC-icon-size-md {
        transform: scaleX(54.6875%) scaleY(54.6875%);
      }
      .GLEEC-icon-size-sm {
        transform: scaleX(46.875%) scaleY(46.875%);
      }
    
      .HODL-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .HODL-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .ILN-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .ILN-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .JUMBLR-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .JUMBLR-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .KMD-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .KMD-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .KOIN-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .KOIN-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .LABS-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .LABS-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .MARTY-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .MARTY-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .MCL-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .MCL-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .MDX-icon-size-md {
        transform: scaleX(2.9686174724342664%) scaleY(2.9686174724342664%);
      }
      .MDX-icon-size-sm {
        transform: scaleX(2.5445292620865136%) scaleY(2.5445292620865136%);
      }
    
      .MESH-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .MESH-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .MGW-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .MGW-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .MORTY-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .MORTY-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .MSHARK-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .MSHARK-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .NINJA-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .NINJA-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .PANGEA-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .PANGEA-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .PGT-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .PGT-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .RAPH-icon-size-md {
        transform: scaleX(7.20164609053498%) scaleY(7.20164609053498%);
      }
      .RAPH-icon-size-sm {
        transform: scaleX(6.172839506172839%) scaleY(6.172839506172839%);
      }
    
      .REVS-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .REVS-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .RICK-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .RICK-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .SOULJA-icon-size-md {
        transform: scaleX(43.75%) scaleY(34.31372549019608%);
      }
      .SOULJA-icon-size-sm {
        transform: scaleX(37.5%) scaleY(29.411764705882355%);
      }
    
      .SPACE-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .SPACE-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .SUPERNET-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .SUPERNET-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .THC-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .THC-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .TKLTEST-icon-size-md {
        transform: scaleX(30.17241379310345%) scaleY(33.0188679245283%);
      }
      .TKLTEST-icon-size-sm {
        transform: scaleX(25.862068965517242%) scaleY(28.30188679245283%);
      }
    
      .TOKEL-icon-size-md {
        transform: scaleX(30.17241379310345%) scaleY(33.0188679245283%);
      }
      .TOKEL-icon-size-sm {
        transform: scaleX(25.862068965517242%) scaleY(28.30188679245283%);
      }
    
      .VOTE2024-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .VOTE2024-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .VRSC-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .VRSC-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .WLC21-icon-size-md {
        transform: scaleX(43.75%) scaleY(43.75%);
      }
      .WLC21-icon-size-sm {
        transform: scaleX(37.5%) scaleY(37.5%);
      }
    
      .WSB-icon-size-md {
        transform: scaleX(54.6875%) scaleY(54.6875%);
      }
      .WSB-icon-size-sm {
        transform: scaleX(46.875%) scaleY(46.875%);
      }
    