.sidebar-right {
  position: fixed;
  top: 52px;
  height: 100vh;
  width: 90px;
  background: #121958;
  color: #fff;
  z-index: 100;

  > ul {
    > li {
      text-align: center;
      padding: 20px 0;
      border-left: solid 4px #121958;

      i {
        font-size: 22px;
        position: relative;
        left: -4px;
      }

      * > i,
      > i {
        width: 30px !important;
      }

      &:hover {
        cursor: pointer;
        border-left: solid 4px #25CDDA;

        i {
          color: #25CDDA;
        }
      }

      .plane-icon-invert {
        transform: rotate(175deg);

        left: -10px;
      }
    }
  }

  > a,
  > a:hover {
    color: #fff !important;
  }

  &.sidebar-full {
    width: 200px;

    > ul {
      > li {
        text-align: left;
        padding-left: 20px;

        .sidebar-item-title {
          position: relative;
          left: 5px;
          top: -2px;
        }
      }
    }
  }

  .sidebar-item-no-pad {
    padding: 0;

    a {
      display: block;
      padding: 20px 0;
      text-decoration: none;
      color: #fff !important;
    }
  }
}

.sidebar-app-version {
  position: absolute;
  bottom: 55px;
  font-size: 0.9em;
  text-align: center;
  width: 100%;
}