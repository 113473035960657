.Accounts {
  padding: 0 1.5rem;

  .receive-btn {
    margin-left: 0 !important;
    position: absolute;
    right: 20px;
    margin-top: -88px;
  }

  .button.is-primary {
    padding-left: 0.85rem !important;
  }
  .button.send-max {
    padding: 0 10px;
    height: 2rem;
    text-transform: uppercase;
    font-size: 0.95rem;
  }
}
