@import './bulma-custom.scss';

.App {
  font-family: $family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  min-width: 600px;

  .download-desktop-block {
    text-align: center;
    padding-bottom: 20px;

    a:hover {
      text-decoration: none;
    }
    button {
      padding: 15px;
      height: 2.5rem;
      
      i {
        padding-right: 7px;
        position: relative;
        top: -2px;
      }
    }
  }

  .add-pwa-button {
    position: absolute;
    top: 8px;
    left: calc(100% - 180px);
    z-index: 1000;
  }

  .col-warning {
    color: #ffc107;
  }

  .main {
    flex: 1;
    padding-top: 5.4em;

    &.beta-warning-fix {
      padding-top: 0;
    }

    .ws--nowrap {
      white-space: nowrap;
    }
  
    .wb--all {
      word-break: break-all;
    }
  
    .cap--first {
      text-transform: capitalize;
    }

    .form-control {
      &.edit {
        padding: 5px 10px;
        max-width: 320px;
        width: 100%;
        font-size: 14px;
      }
    }

    .hw-graphic {
      height: 140px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.5);
    }
    
    .vendor-ledger {
      height: 60px;
      margin-right: 100px;
      cursor: pointer;
    }
    
    .vendor-trezor {
      height: 60px;
      cursor: pointer;
    }
    
    .vendor-selector h3 {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding: 40px 0;
    }
    
    .vendor-selector-items {
      text-align: center;
      display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
    }
    
    .content {
      padding: 0 20px;
    }

    .ledger-device-selector {
      margin-top: 70px;
      height: 35px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .ledger-device-selector-buttons {
        text-align: center;

        .button {
          background: #fff;

          &:last-child {
            margin-left: 40px;
          }
        }
      }

      .ledger-fw-version-selector-block {
        margin-top: 20px;

        .ledger-fw-selector {
          margin-left: 10px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.download-desktop-block {
  text-align: center;
  padding-top: 50px;

  a:hover {
    text-decoration: none;
  }
  button {
    padding: 15px;
    height: 2.5rem;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    background: #fff !important;

    i {
      padding-right: 7px;
      position: relative;
      top: -2px;
    }
  }
}