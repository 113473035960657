.Beta-warning {
  min-height: 2rem !important;
  text-align: center;
  border-bottom: solid 1px rgba(26, 41, 57, 0.1);
  z-index: 10 !important;
  margin-top: 4.5em;
  margin-bottom: 45px;

  .container {
    min-height: 2rem !important;
  
    span {
      margin: 0 auto;
      position: relative;
      top: 4px;

      strong {
        color: #ffc107;
      }
    }
  }
}