/*
  converted from React style props
  requires !important in most cases or better class narrowing down :(
*/

.coin-settings-margin-left {
  margin-left: 7px !important;
}

.fw-check-modal-padding-bottom {
  padding-bottom: 10px !important;
}

.login-modal-input-pw {
  margin-left: 10px !important;
  padding: 5px !important;
  width: calc(100% - 100px) !important;
}

.login-modal-padding-top {
  padding-top: 20px !important;
}

.login-modal-float-right {
  float: right !important;
}

.send-coin-modal-style1 {
  padding: 0 20px 30px 0 !important;
}

.send-coin-modal-style2 {
  margin: 30px 0 20px 0 !important;
}

.send-coin-modal-style3 {
  margin-left: 10px !important;
}

.send-coin-modal-style4 {
  padding: 10px 20px 20px 20px !important;
}

.send-coin-modal-style5 {
  padding: 10px 20px 30px 20px !important;
}

.send-coin-modal-style6 {
  padding: 0 20px 30px 20px !important;
}

.send-coin-modal-debug-style1 {
  padding: 10px 0 !important;
  display: block !important;
}

.send-coin-modal-debug-style2 {
  word-break: break-all !important;
  display: block !important;
  padding-left: 3px !important;
}