.Modal-add-coin {
  .modal-content {
    width: 704px;
  }

  .block-coin-icons {    
    .block-coin-icons-tile {
      user-select: none;
      display: inline-block;
      height: 45px;
      width: 170px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      margin-right: 35px;
      margin-bottom: 35px;
      cursor: pointer;
      transition: box-shadow 0.2s;
      word-break: keep-all;

      .check-icon {
        position: absolute;
        margin-top: 27px;
        margin-left: 153px;
      }
      .block-coin-icons-tile-name {
        position: relative;
        left: 18px;
        top: -6px;
      }
  
      &:hover {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
      }
  
      img {
        height: 35px;
        width: 35px;
        position: relative;
        top: 6px;
        left: 5px;
      }
    }
  }

  .switch {
    margin: 7px 0 10px 20px;
  }

  .select-coin-airdrop-settings {
    padding-bottom: 20px;
  }
}

.coin-actions-modal-trigger,
.coin-add-account-modal-trigger {
  position: absolute;
  right: 20px;
  margin-top: -35px;
  height: 25px;
  width: 25px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.2s;
  font-size: 15px;

  &:hover {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  }

  i {
    height: 20px;
    width: 20px;
    position: relative;
    top: 2px;
    left: 5px;
  }
}

.coin-add-account-modal-trigger {
  right: 55px;
  margin-top: 2px;

  i {
    left: 6px;
  }
}