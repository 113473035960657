.Modal-utxos {
  .modal-content {
    width: 100%;
  }

  .table {
    th {
      font-weight: 500;
    }
    tbody {
      th {
        word-break: break-all;
      }
    }
  }
}

.check-utxos-btn {
  margin-top: 30px !important;
  margin-bottom: 0 !important;
  margin-left: 20px;
  padding-left: 0.25em !important;

  i {
    font-size: 18px;
  }
}