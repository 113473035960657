@import './bulma-custom.scss';
@import './App.scss';
@import './uiv2.scss';
@import './Select.scss';
@import './coin-icons.scss';
@import './stylesFix.scss';

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik';
  
  * {
    font-family: 'Rubik';
  }
}

html {
  background: inherit !important;
}

.trezor-link {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-decoration: none;
 flex-direction: column;
 max-width: 300px;
 text-align: left;
}

.trezor-link span {
  font-size: 16px;
  padding-top: 20px;
  padding-left: 10px;
  color: #121958;
}

.vendor-trezor,
.vendor-ledger {
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
}

.hw-graphic {
  background: #fff;
  padding: 20px 30px;
  border-radius: 4px;
}

.text-center {
  text-align: center !important;
}

.theme-selector {
  .item {
    position: relative;
    top: 2px;
    border: solid 1px transparent;
    width: 13px;
    height: 13px;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;

    &.black {
      background: #000;
    }
    &.light {
      background: #cacaca;
    }
    &:hover {
      border: solid 1px #fff;
    }
    &.active {
      border: solid 1px rgba(255, 255, 255, 0.7);
      cursor: default;
    }
    &.light.active {
      border: solid 1px rgba(168, 168, 168, 0.822);
    }
  }
}

.button.is-primary {
  background: #093ec3e0 !important;
  color: #fff !important;
}

.modal-action-block {
  &.center {
    text-align: center;
  }
}