@import './bulma-custom.scss';

.ActionListModal .panel-block {
  position: relative;
  padding: 0 3rem;
  min-height: 4rem;
}
.ActionListModal .panel-block:first-child {
  border-radius: $radius $radius 0 0;
}
.ActionListModal .panel-block:last-child {
  border-radius: 0 0 $radius $radius;
}

.ActionListModal .right-icon {
  position: absolute;
  right: 1.5rem;
  transform: translateX(50%);
}

.ActionListModal .left-icon {
  position: absolute;
  left: 1.5rem;
  transform: translateX(-50%);
}
