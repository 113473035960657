.recent-operations-block {
  display: block;

  h4 {
    font-size: 18px;
    color: #121958 !important;
  }

  .recent-operations {
    border-radius: 6px;
    background: #fff;
    padding: 20px 20px 0 20px;
    margin-top: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);

    .item {
      height: 60px;

      .item-right-pane {
        float: right;
        width: 50%;
        overflow: hidden;
        height: 30px;
        text-align: right;
        word-break: break-all;
        background: #fff;
        position: relative;
        padding-bottom: 36px;
        
        .amount-increase {
          color: #13D0A7;
          font-weight: 500;
          font-size: 18px;
          position: relative;
          top: 6px;
        }
        .amount-decrease {
          color: #4f5058;
          font-weight: 500;
          font-size: 18px;
          position: relative;
          top: 6px;
        }
      }
      .item-left-pane {
        float: left;

        .date {
          color: #a9a9a9;
          position: absolute;
          margin-top: 17px;
          font-size: 13px;
          margin-left: 10px;
        }
        .direction {
          margin-left: 10px;
          position: absolute;
          margin-top: -3px;
          text-transform: capitalize;
        }
        img,
        .coin-icons-wrapper-container {
          display: inline-block;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}